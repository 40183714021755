import React from 'react'
import { graphql } from 'gatsby'

import { PageLayout, SKUPage } from '../components'

const SKUPageTemplate = ({ data, pageContext }) => {
  const { lang, product, sku, productfamily, attributes, attribute_groups, skus } = pageContext

  return (
    <PageLayout lang={lang} switcher={sku}>
      <SKUPage
        product={product}
        sku={sku}
        productfamily={productfamily}
        productfamilyitems={skus}
        attributes={attributes}
        attribute_groups={attribute_groups}
        lang={lang} />
    </PageLayout>
  )
}

export default SKUPageTemplate
